import React, { Component } from 'react'
import { GenericPageLayout, StoryPageLayout } from '../../../components/layouts'
import { withPreview } from '../../../components/common'
import { isEmpty } from 'lodash'

class GenericPTPPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: null,
            sitemap: [],
            hasGroup: false,
            sitemap: [],
        }
    }

    componentDidMount() {
        sessionStorage.setItem('preview', true)
        this.props
            .getPowerToProtectSitemap()
            .then((sitemap) => {
                this.setState({
                    sitemap,
                })
            })
            .catch((error) => {
                console.error('Error fetching sitemap:', error)
            })

        this.props
            .getPowerToProtectEntry('pageGenericPTP', this.props.location.search)
            .then((entry) => {
                if (entry) {
                    this.initializeGenericPage(entry)
                } else {
                    console.error('Entry is null or undefined')
                }
            })
            .catch((error) => {
                console.error('Error fetching entry:', error)
            })
    }

    componentWillUnmount() {
        sessionStorage.setItem('preview', false)
    }

    initializeGenericPage(entry) {
        if (!entry || !entry.fields) {
            console.error('Entry or entry fields are null or undefined')
            return
        }

        const heroImage = entry.fields.heroImage ? entry.fields.heroImage.fields : null
        const storyBlock = entry.fields.storyBlock
            ? {
                  ...entry.fields.storyBlock.fields,
                  backgroundImage: entry.fields.storyBlock.fields.backgroundImage ? entry.fields.storyBlock.fields.backgroundImage.fields : null,
                  story: entry.fields.storyBlock.fields.story ? entry.fields.storyBlock.fields.story.fields : null,
              }
            : null
        const groups = entry.fields.groups ? entry.fields.groups.map((group) => group.fields) : []

        const page = {
            __typename: 'ContentfulPageGenericPtp',
            ...entry.fields,
            layout: { layout: entry.fields.layout },
            richText: { ...entry.fields.richText },
            heroImage: heroImage,
            storyBlock: storyBlock,
            groups: groups,
        }

        this.setState({
            page,
            hasGroup: !isEmpty(page.groups),
        })
    }

    renderPage(page, hasGroup) {
        const site = this.props.getSiteInfo()
        if (page) {
            const storyPage = { ...page, content: page.richText }
            switch (page.layout.layout) {
                case 'Story':
                    return !this.props.loading && page && site ? <StoryPageLayout className="ptp-root" preview={true} site={site} story={storyPage} sitemap={this.state.sitemap} /> : <></>
                default:
                    return !this.props.loading && page && site ? <GenericPageLayout className="ptp-root" preview={true} site={site} page={page} hasGroup={hasGroup} sitemap={this.state.sitemap} /> : <></>
            }
        }
        return <></>
    }

    render() {
        const { page, hasGroup } = this.state
        return this.renderPage(page, hasGroup)
    }
}

export default withPreview(GenericPTPPagePreview)
